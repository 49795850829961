//订单相关
import { $http } from '../plugins/axios'
const { post, get } = $http
//获取货币汇率列表
export const huiLvList = (params) => {
    return get('fangshan/dm-new/huiLvList', { ...params })
}
//获取国家，省市区
export const getCpc = (params) => {
    return get('fangshan/dm-new/cityList', { ...params })
}
//订单初始化
export const initOneTwoOrder = (params) => {
    return post('fangshan/dm-order/initOneTwoOrder', { ...params })
}
//专车服务、接送机 根据地点计算价格
export const initOrderOneTwoMoney = (params) => {
    return post('fangshan/dm-order/initOrderOneTwoMoney', { ...params })
}
//计算单次包车的价格
export function calculateSingleCharterPrice(params) {
    return post("/fangshan/dm-order/calculateSingleCharterPrice", { ...params })
}
//获取车型列表
export function listAllCarConfig(params) {
    return get("/fangshan/dm-user/listAllCarConfig", { ...params })
}
//获取订单列表
export const pageAllOrder = (pageNum, pageSize, orderStatus) => {
    return get(`fangshan/dm-order/pageAllOrder?pageNum=${pageNum}&pageSize=${pageSize}&orderStatus=${orderStatus}`)
}
//取消订单
export const cancelOrder = (id) => {
    return post(`fangshan/dm-order/cancelOrder?id=${id}`)
}
//用车时长列表
export function listTime(params) {
    return get("/fangshan/dm-user/listTime", { ...params })
}
//用户优惠券列表
export function userCouponList(params) {
    return get("/fangshan/dm-user/userCouponList", { ...params })
}
//2024/09/06
//微信支付
export const setWxPay = (params) => {
    return post(`/fangshan/pay/codePay`,params)
}
//支付宝支付
export const setzfbPay = (params) => {
    return post(`/fangshan/pay/AlipayScanPay`,params)
}
//判断是否支付
export const setCodePayEnd = (params) => {
    return get(`/fangshan/pay/codeUserPayEnd`,params)
}