<template>
    <div class="centers">
        <div class="box">
            <div class="title">确认订单</div>

            <!-- 订单类型 -->
            <div class="type" v-if="type == 5">单次包车</div>
            <div class="type" v-if="type == 3">接送机服务</div>
            <div class="type" v-if="type == 4">汽车租赁</div>
            <div class="type" v-if="type == 6">旅游产品</div>
            <div class="type" v-if="type == 2">专车服务</div>
            <div class="type" v-if="type == 1">热门目的地</div>

            <!-- 订单信息 -->
            <div class="info-box">
                <div v-if="type == 5">
                    <div class="info">出发地：{{ orders.cfGuo + orders.cfSheng + orders.cfShi + orders.setOutAddress }}</div>
                </div>
                <div v-if="type == 2">
                    <div class="info">{{ orders.setOutAddress }} —— {{ orders.arriveOutAddress }}</div>
                </div>
                <div v-if="type == 3">
                    <div class="info">{{ orders.setOutAddress }} —— {{ orders.arriveOutAddress }} ; 航班号：{{
                        orders.airplaneNo }}</div>
                    <div class="info">预约到达时间：{{ orders.appointmentTime }}</div>
                </div>
                <div v-if="type == 1 || type == 6">
                    <div class="info">{{ orders.info.desName }}</div>
                </div>
                <div v-if="type == 4">
                    <div class="info">{{ carInfo.desName }}</div>
                </div>
            </div>

            <!-- 价格 -->
            <div class="price" v-if="type == 4">￥{{ carInfo.desMoney }} <span>/天</span></div>
            <div class="price" v-else>￥ {{ orders.allMoney }}</div>


            <!-- 顾客信息 -->
            <div class="client">
                <el-input placeholder="请输入联系人姓名" v-model="name"></el-input>
                <el-input placeholder="请输入联系电话" v-model="phone"></el-input>
                <el-input placeholder="请输入联系邮箱" v-model="email" v-if="type == 6"></el-input>
                <!-- <el-input placeholder="请输入联系邮箱" v-model="phone"></el-input> -->
            </div>
            <div class="inputs flexs1" v-if="type == 6">
                <el-date-picker v-model="Stime" type="datetime" placeholder="选择服务日期" default-time="12:00:00"
                    @change="getTime" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
                <div class="flexs1" style="margin-left: 20px;">
                    <div>帮人预定：</div>
                    <el-radio-group v-model="radioBang">
                        <el-radio :label="1">否</el-radio>
                        <el-radio :label="2">是</el-radio>
                    </el-radio-group>
                </div>

            </div>
            <div class="client" style="margin-top: 24px;" v-if="radioBang == 2 && type == 6">
                <el-input placeholder="请输入联系人姓名" v-model="bname"></el-input>
                <el-input placeholder="请输入联系电话" v-model="bphone"></el-input>
            </div>
            <div class="inputs">
                <span class="tits">租赁天数：</span> <el-input-number v-model="DayNum" :min="1" size="small"></el-input-number>
            </div>
            <!-- <el-input placeholder="请输入联系电话" v-model="phone"></el-input> -->

            <div class="discount" v-if="showYHQ">优惠券:</div>
            <div class="discountList" v-if="showYHQ">
                <el-carousel trigger="click" height="100px" :interval="60000" :loop="false" @change="change">
                    <el-carousel-item v-for="item in Math.ceil(total / 3)" :key="item">
                        <div class="item">
                            <el-radio-group v-model="radio" @input="input">
                                <el-radio :label="JSON.stringify(item)" v-for="item in list" :key="item.id">
                                    <div class="discounts">
                                        <div class="right">￥<span>{{ item.couponMoney }}</span></div>
                                        <div class="gap"></div>
                                        <div class="left">
                                            <div class="name">{{ item.couponTitle }}</div>
                                            <div class="time" v-if="item.couponTopMoney">满{{ item.couponTopMoney }}元可用
                                            </div>
                                            <div class="time" v-else>无门槛</div>
                                            <div class="time">有效期至：{{ item.effectiveTime.slice(0, 11) }}</div>
                                        </div>
                                    </div>
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="zhifu">
                <div class="tit">支付方式:</div>
                <el-radio-group v-model="radio2">
                    <el-radio :label="1">支付宝</el-radio>
                    <el-radio :label="2">微信</el-radio>
                </el-radio-group>
            </div>
            <div class="titles" v-if="type == 4">
                <div>订单总价：<span>￥{{ carPrice }}</span></div>
                <div>会员优惠：<span>-￥{{ carYPrice }}</span></div>
                <div>优惠券折扣：<span>-￥{{ coupon }}</span></div>
            </div>
            <div class="titles" v-else>
                <div>会员优惠：<span>-￥{{ DisPrice }}</span></div>
                <div>优惠券折扣：<span>-￥{{ coupon }}</span></div>
            </div>

            <div class="price-tit">实付价格</div>
            <div class="pri" v-if="type == 4">￥{{ carZPrice }}</div>
            <div class="pri" v-else>￥{{ totalPrices }}</div>
            <div class="price-tit">货币兑换</div>
            <div class="inputs" style="padding-top: 0;margin-bottom: 10px;">
                <el-select placeholder="请选择" @change="getCard" v-model="huiLv.configId">
                    <el-option v-for="item in options" :key="item.configId" :label="item.remark" :value="item.configId">
                    </el-option>
                </el-select>
            </div>
            <div class="titles" v-if="huiLv">兑换价格：<span>{{ duiZPrice }}</span></div>

            <div class="btns">
                <button class="submit-btn" @click="gopay">确认支付</button>
            </div>
        </div>
        <!-- 支付弹窗 -->
        <el-dialog title="扫码支付" :visible.sync="isPay" width="500" @close="cancelDialog">
            <div class="code-img">
                <div>
                    <span>剩余支付时间：<span style="color:red">{{ min }}:{{ sec }}</span></span>
                </div>
                <img class="imgs" :src="codeData.urlCode">
                <div class="tips" v-if="codeData.urlCode">{{ radio2 == 1 ? '支付宝扫码支付' : '微信扫码支付' }}</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog()">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { userCouponList, getHighImportInfo } from "@/api/mine"
import { initOneTwoOrder, huiLvList, setWxPay, setzfbPay, setCodePayEnd } from '@/api/order'
export default {
    name: 'order',
    data() {
        return {
            options: [],
            name: '',
            phone: '',
            email: '',
            bname: '',
            bphone: '',
            Stime: '',
            radio: {}, //选中的优惠券对象
            pageNum: 1,
            pageSize: 3,
            list: [], //优惠券列表
            total: 0,
            radio2: 1,
            radioBang: 1,
            type: 0,
            orders: {}, //订单信息详情
            discount: 1,//会员折扣
            coupon: '0.00', //优惠券金额,
            carInfo: {},//汽车租赁详情,
            DayNum: 1,//租赁天数
            showYHQ: false,
            huiLv: '',

            //扫码支付弹窗
            isPay: false,
            codeData: {
                id: '',
                urlCode: '',
            },
            intervalId: '',
            //倒计时
            min: '',
            sec: '',
            end: '',

        }
    },
    computed: {
        //会员的折扣价格（除汽车租赁外）
        DisPrice() {
            return (parseInt(this.orders.allMoney) - parseInt(this.orders.allMoney) * parseFloat(this.discount))
                .toFixed(2)
        },
        //实付价（除汽车租赁外）
        totalPrices() {
            return (parseInt(this.orders.allMoney) - this.DisPrice - this.coupon).toFixed(2)
        },
        //汽车租赁总价
        carPrice() {
            return (parseInt(this.carInfo.desMoney) * this.DayNum).toFixed(2)
        },
        //汽车租赁折扣价格
        carYPrice() {
            return (parseInt(this.carPrice) - parseInt(this.carPrice) * parseFloat(this.discount)).toFixed(2)
        },
        //汽车租赁实付价
        carZPrice() {
            this.shifujia = (this.carPrice - this.carYPrice - this.coupon).toFixed(2)
            return this.shifujia
        },
        //兑换价格
        duiZPrice() {

            return (this.totalPrices / this.huiLv.configValue).toFixed(2)
        }
    },
    created() {
        if (this.$route.query.obj) this.orders = JSON.parse(decodeURIComponent(this.$route.query.obj))
        if (this.$route.query.carinfo) this.carInfo = JSON.parse(decodeURIComponent(this.$route.query.carinfo))
        this.type = this.$route.query.type
        console.log(this.carInfo)
        // console.log(this.type)
        if (localStorage.getItem('token')) {
            this.getList()
            this.getUser()
            this.showYHQ = true

        }
        huiLvList().then(res => {
            this.options = res.data
        })
    },
    mounted() {

    },
    methods: {
        //获取车型id
        getCard(e) {
            this.huiLv = this.options.filter(i => i.configId == e)[0]
        },
        getTime() {

        },
        //单选
        input(e) {
            if (this.type == 4) {
                if (this.carPrice >= JSON.parse(e).couponTopMoney) {
                    this.coupon = JSON.parse(e).couponMoney.toFixed(2)
                } else {
                    this.$message.warning('不满足该优惠券的使用要求')
                    this.radio = {}
                    this.coupon = '0.00'
                }
            } else {
                if (this.orders.allMoney >= JSON.parse(e).couponTopMoney) {
                    this.coupon = JSON.parse(e).couponMoney.toFixed(2)
                } else {
                    this.$message.warning('不满足该优惠券的使用要求')
                    this.radio = {}
                    this.coupon = '0.00'
                }
            }
        },
        change(e) {
            this.pageNum = e + 1
            this.getList()
        },
        getUser() {
            getHighImportInfo().then(res => {
                this.discount = res.data.vipDiscount
            })
        },
        getList() {
            userCouponList({
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }).then(res => {
                this.list = res.rows
                this.total = res.total
            })
        },
        gopay() {
            if (this.type == 4) {
                if (!localStorage.getItem('token')) {
                    this.$message.warning('您必须登录后才能创建订单哦！')
                    setTimeout(() => {
                        this.$store.commit('SHOWLOGIN')
                    }, 1000);
                    return
                }
                //汽车租赁订单
                if (this.name == '') {
                    this.$message.warning('请输入联系人姓名')
                    return
                }
                if (this.phone == '') {
                    this.$message.warning('请输入联系人电话')
                    return
                }
                let obg = {
                    orderSource: 'PC',
                    orderTypeCode: 'CAR_RENTAL',
                    name: this.name,
                    phone: this.phone,
                    refCouponId: this.coupon != '0.00' ? JSON.parse(this.radio).id : '',
                    couponMoney: this.coupon != '0.00' ? JSON.parse(this.radio).couponMoney : 0,
                    vipDiscount: this.carYPrice,
                    sfMoney: this.carZPrice,
                    rentNum: this.DayNum,
                    refDestinationId: this.carInfo.id,
                    payWay: this.radio2,
                }
                initOneTwoOrder(obg).then(res => {
                    if (res.code === 200) {
                        this.openPay(res.data.id)
                        // this.$message.success('订单创建成功')
                        // this.$message.success('支付成功')
                        // setTimeout(() => {
                        //     this.$router.push('centre/order')
                        // }, 500);

                    } else {
                        this.$message.error(res.msg)
                    }
                })

            } else if (this.type == 6) {
                if (!localStorage.getItem('token')) {
                    this.$message.warning('您必须登录后才能创建订单哦！')
                    setTimeout(() => {
                        this.$store.commit('SHOWLOGIN')
                    }, 1000);
                    return
                }
                if (this.name == '') {
                    this.$message.warning('请输入联系人姓名')
                    return
                }
                if (this.phone == '') {
                    this.$message.warning('请输入联系人电话')
                    return
                }
                if (this.Stime == '') {
                    this.$message.warning('请选择服务日期')
                    return
                }
                if (this.radioBang == 2) {
                    if (this.bname == '') {
                        this.$message.warning('请输入帮助人姓名')
                        return
                    }
                    if (this.bphone == '') {
                        this.$message.warning('请输入帮助人电话')
                        return
                    }
                }
                let obg = {
                    name: this.name,
                    phone: this.phone,
                    beName: this.bname,
                    bePhone: this.bphone,
                    refCouponId: this.coupon != '0.00' ? JSON.parse(this.radio).id : '',
                    couponMoney: this.coupon != '0.00' ? JSON.parse(this.radio).couponMoney : 0,
                    vipDiscount: this.DisPrice,
                    sfMoney: this.totalPrices,
                    appointmentTime: this.Stime,
                    payWay: this.radio2,
                }
                let a = JSON.parse(JSON.stringify(this.orders))
                delete a.info
                let order = {
                    ...a,
                    ...obg
                }
                initOneTwoOrder(order).then(res => {
                    if (res.code === 200) {
                        this.openPay(res.data.id)
                        // this.$message.success('订单创建成功')
                        // this.$message.success('支付成功')
                        // setTimeout(() => {
                        //     this.$router.push('centre/order')
                        // }, 500);

                    } else {
                        this.$message.error(res.msg)
                    }
                })
                //旅游产品订单
            } else {
                if (!localStorage.getItem('token')) {
                    this.$message.warning('您必须登录后才能创建订单哦！')
                    setTimeout(() => {
                        this.$store.commit('SHOWLOGIN')
                    }, 1000);
                    return
                }
                //其他订单
                if (this.name == '') {
                    this.$message.warning('请输入联系人姓名')
                    return
                }
                if (this.phone == '') {
                    this.$message.warning('请输入联系人电话')
                    return
                }
                let obg = {
                    name: this.name,
                    phone: this.phone,
                    refCouponId: this.coupon != '0.00' ? JSON.parse(this.radio).id : '',
                    couponMoney: this.coupon != '0.00' ? JSON.parse(this.radio).couponMoney : 0,
                    vipDiscount: this.DisPrice,
                    sfMoney: this.totalPrices,
                    payWay: this.radio2,
                }
                let a = JSON.parse(JSON.stringify(this.orders))
                delete a.info
                let order = {
                    ...a,
                    ...obg
                }
                initOneTwoOrder(order).then(res => {
                    if (res.code === 200) {
                        this.openPay(res.data.id)
                        // this.$message.success('订单创建成功')
                        // this.$message.success('支付成功')
                        // setTimeout(() => {
                        //     this.$router.push('centre/order')
                        // }, 500);

                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        //打开支付
        openPay(orderId) {
            let params = {
                orderId: orderId,
                orderType: 2, //1企业 2用户
            }
            if (this.radio2 == 1) {
                //支付宝
                setzfbPay(params).then(res => {
                    if (res.code == 200) {
                        this.isPay = true
                        this.codeData = res.data || {}
                        this.intervalId = setInterval(() => {
                            this.handlePay(this.codeData.id)
                        }, 3000)
                    }
                })
            } else {
                //微信
                setWxPay(params).then(res => {
                    if (res.code == 200) {
                        this.isPay = true
                        this.codeData = res.data || {}
                        this.intervalId = setInterval(() => {
                            this.handlePay(this.codeData.id)
                        }, 3000)
                    }
                })
            }
            //定义五分钟结束
            this.end = Date.parse(new Date()) + 300000
            this.countdown()

        },
        //取消支付弹窗
        cancelDialog() {
            this.isPay = false
            clearInterval(this.intervalId);
        },
        //判断是否付款
        handlePay(id) {
            setCodePayEnd({
                id: id
            }).then(res => {
                if (res.code == 200) {
                    clearInterval(this.intervalId);
                    this.$message.success('支付成功')
                    this.isPay = false
                    setTimeout(() => {
                        this.$router.push('centre/order')
                    }, 1000);
                }
            })
        },
        //倒计时5分钟
        countdown() {
            let end = this.end
            const now = Date.parse(new Date())
            const msec = end - now
            if (msec < 0) return;
            let day = parseInt(msec / 1000 / 60 / 60 / 24)
            let hr = parseInt(msec / 1000 / 60 / 60 % 24)
            let min = parseInt(msec / 1000 / 60 % 60)
            let sec = parseInt(msec / 1000 % 60)
            //this.day = day
            // this.hr = hr > 9 ? hr : '0' + hr
            this.min = min > 9 ? min : '0' + min
            this.sec = sec > 9 ? sec : '0' + sec
            const that = this
            if (min >= 0 && sec >= 0) {
                //倒计时结束关闭订单
                if (min == 0 && sec == 0) {
                    this.$message.warning('二维码已失效')
                    setTimeout(() => {
                        this.$router.push('centre/order')
                    }, 1000);
                    return
                }
                setTimeout(function () {
                    that.countdown()
                }, 1000)
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.flexs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexs1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flexs2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexs3 {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flexs4 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: flex-end;
}

.inputs {
    padding-top: 24px;

    .tits {
        font-size: 17px;
    }
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    background: #0D5E6B;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #0c5e6b;
}

.box {
    margin-top: 20px;
    background: #fff;
    width: 100%;
    padding: 24px;
    border-radius: 12px;

    ::v-deep .el-input__inner {
        background-color: #F3F3F3;
    }

    .title {
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        border-left: 6px solid #0c5e6b;
        padding-left: 10px;
    }

    .type {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0D5E6B;
        margin: 20px 0;
    }

    .info-box {
        .info {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
        }
    }

    .price {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FF0909;
        line-height: 47px;
    }

    .client {
        display: flex;
        gap: 40px;

        .el-input {
            width: 30%;
        }
    }

    .discount {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 20px;
    }

    .discountList {
        height: 100px;
        width: 100%;
        margin: 10px 0;
        box-sizing: border-box;

        ::v-deep .el-carousel__indicators--horizontal {
            display: none !important;
        }

        .el-carousel__item {
            .item {
                width: 88%;
                margin: 0 auto;

                .el-radio-group {
                    display: flex;
                    gap: calc((100% - 834px) / 3);
                }

                .el-radio {
                    display: flex;
                    align-items: center;
                    width: 278px;
                    gap: 20px;
                }

                .discounts {
                    width: 234px;
                    height: 100px;
                    background: rgba($color: #FF2A2A, $alpha: 0.05);
                    display: flex;
                    line-height: 100px;
                    padding: 0 8px;

                    .right {
                        color: #FF2A2A;
                        font-size: 16px;

                        span {
                            font-size: 24px;
                        }
                    }

                    .gap {
                        width: 1px;
                        height: 70px;
                        margin: 15px 10px 0 10px;
                        border: 1px dashed #0000002c;
                    }

                    .left {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .name {
                            font-size: 16px;
                            font-weight: 400;
                            color: #000000;
                            line-height: 25px;
                        }

                        .time {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6B6B6B;
                            line-height: 25px;
                        }
                    }
                }

            }
        }
    }

    .zhifu {
        display: flex;
        align-items: center;
        margin: 34px 0 18px 0;
        gap: 40px;

        .tit {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
        }
    }

    .titles {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 28px;

        span {
            color: #FF0909;
        }
    }

    .price-tit {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin: 14px 0;
    }

    .pri {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FF0909;
        line-height: 30px;
        font-weight: 700;
    }

    .btns {
        padding: 20px 0 0 0;
        width: 25%;
    }
}

//扫码支付
::v-deep .el-dialog{
    width: 500px;
}
.code-img {
    text-align: center;

    .imgs {
        margin: auto;
        width: 240px;
        height: 240px;
    }

    .tips {
        margin: 10px 0;
        font-size: 16px;
        color: #333;
    }
}
</style>